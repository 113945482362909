import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { getBaseUrl } from '~/ZenOne-StoryBook/config/api'

export const getRefreshedPracticeLocation = async (store) => {
  await store.dispatch('user/SetUserPracticeLocation')

  return store.getters['user/practiceLocation']
}

export const isWizardCompleted = (pl = {}) => isHavingProps(pl) && pl.wizardCompleted

export const checkAddressIsFulfilled = (address = {}) => {
  if (!isHavingProps(address)) return false

  return address.city &&
    address.state &&
    address.street &&
    address.building &&
    address.postcode &&
    address.stateAbbr
}

export const completeWizard = async ($axios) => {
  try {
    await $axios.patch(`${getBaseUrl()}/api/v2/practice-locations/complete-wizard`)

    return true
  } catch (e) {
    console.error('Wizard complete error: ', e)
    return false
  }
}

export const acceptAgreement = async ($axios) => {
  try {
    await $axios.patch(`${getBaseUrl()}/api/v2/practice-locations/accept-agreement`)

    return true
  } catch (e) {
    console.error('Accept Service Agreement error: ', e)
    return false
  }
}

export const switchLocation = (id, $router, $store, url = null) => {
  if ($store.getters['user/practiceLocationId'] === id) return

  $store.commit('user/SET_CURRENT_PRACTICE_LOCATION_ID', id)
  $store.dispatch('inventory/SetFolders')
  setLocalStorage({ name: 'currentPracticeLocationId', data: id })

  if (url?.includes('inventory')) {
    window.location.href = '/settings'
  } else {
    $router.go(0)
  }
}
